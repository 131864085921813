import { useLocation } from '@reach/router'

export const useQueryParam = (queryParam: string): string | null | undefined => {
    const search = new URLSearchParams(useLocation().search)
    return search.get(queryParam)
}

export const buildQueryParam = (
    queryParams: Record<string | symbol, string | number | undefined | null>,
): string => {
    const params: string[] = []

    for (const pkey in queryParams) {
        if (queryParams[pkey]) {
            params.push(`${pkey}=${queryParams[pkey]}`)
        }
    }

    return params.length > 0 ? '?' + params.join('&') : ''
}

export const capitalize = (word: string) => {
    const lower = word.toLowerCase()
    return word.charAt(0).toUpperCase() + lower.slice(1)
}
