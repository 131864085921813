import { useIntl } from 'gatsby-plugin-react-intl'

// intl helper to load the content according to the current locale
export const useIntlLabel: () => (
    obj: Record<string, string | any> | any,
    attr: string,
) => string = () => {
    const intl = useIntl()
    return (obj: Record<string, string | any>, attr: string): string => {
        return obj ? String(obj[`${attr}_${intl.locale}`] ?? obj[attr] ?? '') : ''
    }
}
